import React from 'react';
import { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import  {useParams} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }));

  export default function PostById({titleUpdate,updatePost}) {
    let { id } = useParams();
    const [thePost,setThePost] = useState();
    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fetch("https://admin.utzon.info/wp-json/wp/v2/agent_info/"+id).then(res=>res.json())
  .then(
    (result)=>{
        setThePost(result)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        titleUpdate(result.title.rendered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    (error)=>{
        setThePost(error);
    }
  )
        
    },[]);
    const classes = useStyles();
    return (
        <div className={classes.root}>
      {thePost?<Grid container spacing={3}>
        <Paper className={classes.paper}><Typography variant="h4" dangerouslySetInnerHTML={{__html:thePost.title.rendered}}></Typography><Typography dangerouslySetInnerHTML={{__html:thePost.content.rendered}}></Typography></Paper>
      </Grid>:""}
    </div>
    );

  }