import './App.css';
import React from 'react'
import clsx from 'clsx';
import { ThemeProvider  } from '@material-ui/core/styles';
import {
  CssBaseline,
  createMuiTheme,
  AppBar, 
  Toolbar,
  Typography,ListItem, ListItemText, Divider, ListItemIcon
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import { Route, BrowserRouter  as Router, Switch, Redirect, Link} from 'react-router-dom';
import Logon from './Pages/Logon.js'
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Dashboard from './Pages/Dashboard';
import Drawer from '@material-ui/core/Drawer';
import { Home } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import Category from './Pages/Category.js';
import Post from './Pages/Post.js';
import PostById from './Pages/PostById.js'
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';


let tema = {
  breakpoints: {
    keys: ["xs","sm","md","lg","xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    },
  },
  direction: "ltr",
  palette: {
    common: {
      black:"#000",
      white:"#fff",
    },
    type: "dark",
    primary:{
      light:"rgba(150, 237, 225, 1)",
      main:"rgba(100, 186, 175, 1)",
      dark:"rgba(48, 138, 128, 1)",
      contrastText:"rgba(0, 0, 0, 1)"
    },
    secondary:{
      light:"rgba(88, 101, 189, 1)",
      main:"rgba(33, 59, 140, 1)",
      dark:"rgba(0, 22, 94, 1)",
      contrastText:"#fff"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: " #388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary:"#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
      divider: "rgba(255, 255, 255, 0.12)",
      background: {
        paper: "#424242",
        default: "#303030",
      },
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255, 255, 255, 0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },
  props: {},
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em"
    },
    h4: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6:{
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 4
  },
  transitions: {
    easing: {
    easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
    easeIn: "cubic-bezier(0.4, 0, 1, 1)",
    sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
    }
  },
  zIndex: {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  }
  
}
const theme = createMuiTheme(tema);
const drawerWidth = 240;

const useStyles = makeStyles((themet) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuButton: {
    marginRight: themet.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchButton:{

  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchModal:{
    position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  }
}));

function App() {
  const [authenticated,setAuthenticated] = React.useState(false);
  const classes = useStyles();
  const [title,updateTitle] = useState("Utzon Info");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentCategoryPosts, setCurrentCategoryPosts]= useState([]);
  const [currentPost,setCurrentPost]= useState([]);
  const [searchTerm,setSearchTerm] = useState(null);
  const [searchResult,setSearchResult]=useState(null);
  const [openSearch, setOpenSearch] = React.useState(false);
  const handleOpenSearch = () => setOpenSearch(true);
  const handleCloseSearch = () => setOpenSearch(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleDrawerClose = () => {
    setOpen(false);
  };
function PrivaterRoute ({children, ...rest}){
  return(
    <Route {...rest} render={()=> {
      return authenticated=== true?  children:<Redirect to="/"/>; 
    }} />
  )
}
const handleSearch = (e) => {
  e.preventDefault();
  setSearchTerm(e.target.value);
}
const logOut=()=> {
        setCookie("jwt", "", 1);
        setAuthenticated(false);
}
const setCookie=(cname,cvalue,exdays)=>{
  let d = new Date();
  d.setTime(d.getTime()+(exdays*24*60*60*1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
const getCookie =(cname)=>{
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' '){
          c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}
React.useEffect(()=>{
  // eslint-disable-next-line react-hooks/exhaustive-deps
  fetch("https://admin.utzon.info/wp-json/wp/v2/agentemner").then(res=>res.json())
  .then(
      (result) => {
          setIsLoaded(true);
          delete result.post;
          delete result.page;
          delete result.attachment;
          delete result.wp_block;
          setCategories(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
  )
},[]);
React.useEffect(()=>{
  // eslint-disable-next-line react-hooks/exhaustive-deps
  fetch("https://admin.utzon.info/wp-json/wp/v2/agent_info?search="+searchTerm).then(res=>res.json())
  .then(
    (result)=>{
      setSearchResult(result)
    },
    (error)=>{
      setError(error);
    }
  )
},[searchTerm]);
let menulist=<ListItem><ListItemText primary="Loading"/></ListItem>;
if (error) {
menulist = <div>Error: {error.message}</div>;
} else if (!isLoaded) {
menulist = <div>Loading...</div>;
} else {
 menulist = Object.keys(categories).map((cat) => (
  <ListItem button component={Link} to={"/category?name="+categories[cat].slug+"&id="+categories[cat].id} key={categories[cat].slug}>
    <ListItemText primary={categories[cat].name} />
  </ListItem>
))
}

  return (
    <div>
<ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          {authenticated?<IconButton  edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>:""}
          <Typography className={classes.title} variant="h6" noWrap dangerouslySetInnerHTML={{__html:title}}>
          </Typography>
          {authenticated?<Button className={classes.searchButton} onClick={handleOpenSearch} variant="outlined" startIcon={<SearchIcon/>}>Søg…</Button>:""}
          
          {authenticated?<Button onClick={logOut} color="inherit">Log ud</Button>:""}
          
        </Toolbar>
      </AppBar>
      
        <Router basename={`${process.env.PUBLIC_URL}/`}>
        <Dialog
        fullScreen={fullScreen}
        open={openSearch}
        onClose={handleCloseSearch}
        aria-labelledby="responsive-dialog-title"
        scroll={"paper"}
      >
        <DialogTitle id="responsive-dialog-title"><div className={classes.search}><div className={classes.searchIcon}>
<SearchIcon />
</div>
<InputBase
autoFocus
placeholder="Søg…"
value={searchTerm}
classes={{
  root: classes.inputRoot,
  input: classes.inputInput,
}}
inputProps={{ 'aria-label': 'search' }}
onChange={handleSearch}
/></div></DialogTitle>
        <DialogContent onClick={handleCloseSearch}>
          <DialogContentText>
            {searchTerm===null||searchTerm===""?"Begynd at skrive i Søgefeltet for at se relevante opslag":<ul>{Object.keys(searchResult).length>0?Object.keys(searchResult).map((post) => (<Link to={{pathname:"/post/"+searchResult[post].id}} replace><li dangerouslySetInnerHTML={{__html:searchResult[post].title.rendered}}></li></Link>)):"ingen resultater"}</ul>}
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
        <Drawer
      className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </div>
    <List  onClick={handleDrawerClose}>
    {menulist}
    <Divider />
    <ListItem button component={Link} to={"/home"} key={"dashboard"}>
    <ListItemIcon><Home/></ListItemIcon><ListItemText primary={"Oversigt"} />
</ListItem>
    </List></Drawer>
    <main className={clsx(classes.content, {
          [classes.contentShift]: open
        }, open && classes.hide)}>
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path="/" >
              <Logon titleUpdate={updateTitle} setCookie={setCookie} getCookie={getCookie} authenticationUpdate={setAuthenticated} />
            </Route>
            <PrivaterRoute exact path="/home" >
      <Dashboard titleUpdate={updateTitle} updateCurrentPost={setCurrentPost} kategorier={categories} />
      </PrivaterRoute>
      <PrivaterRoute exact path="/category" >
      <Category titleUpdate={updateTitle} currentCategoryPosts={currentCategoryPosts} updateCatPosts={setCurrentCategoryPosts} updateCurrentPost={setCurrentPost}/>
      </PrivaterRoute>
      <PrivaterRoute exact path="/post" >
      <Post titleUpdate={updateTitle} post={currentPost}/>
      </PrivaterRoute>
      <PrivaterRoute exact path="/post/:id">
        <PostById titleUpdate={updateTitle} updatePost={setCurrentPost}/>
        </PrivaterRoute>
            <Route >
            <Logon titleUpdate={updateTitle} setCookie={setCookie} getCookie={getCookie} authenticationUpdate={setAuthenticated} />
            </Route>
          </Switch>
          </main>
        </Router>
        
        </ThemeProvider>
    </div>
  );
}

export default App;
