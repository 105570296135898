import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }));

  export default function Post({titleUpdate,post}) {
    useEffect(()=>{
      // eslint-disable-next-line react-hooks/exhaustive-deps
        titleUpdate(post.title.rendered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const classes = useStyles();
    return (
        <div className={classes.root}>
      <Grid container spacing={3}>
        <Paper className={classes.paper}><Typography variant="h4" dangerouslySetInnerHTML={{__html:post.title.rendered}}></Typography><Typography dangerouslySetInnerHTML={{__html:post.content.rendered}}></Typography></Paper>
      </Grid>
    </div>
    );

  }