import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {
  useLocation,useHistory
} from "react-router-dom";
import placeholderImg from '../Images/kimuc.png';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
export default function Category({titleUpdate, currentCategoryPosts,updateCatPosts,updateCurrentPost}) {
  const history=useHistory();
    let query = useQuery();
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [catPosts, setCatPosts] = React.useState([]);
    const navigatePost=(post)=>{
      updateCurrentPost(post);
      history.push("/post");
    }
    if(error!==null && !isLoaded){
      alert("Error occured, Couldnt fetch the posts for this category")
    }
    useEffect(()=>{
      // eslint-disable-next-line react-hooks/exhaustive-deps
        fetch("https://admin.utzon.info/wp-json/wp/v2/agent_info?agentemner="+query.get("id")).then(res=>res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                delete result.post;
                delete result.page;
                delete result.attachment;
                delete result.wp_block;
                setCatPosts(result);
               
              },
              (error) => { 
                setIsLoaded(true);
                setError(error);
              }
        );
        fetch("https://admin.utzon.info/wp-json/wp/v2/agentemner/"+query.get("id")).then(res=>res.json())
      .then(
        (result)=>{
          // eslint-disable-next-line react-hooks/exhaustive-deps
          titleUpdate(result.name);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        (error) =>{
          alert("Ingen kategori fundet");
        });
      }
      
      ,[]);
      
     
    const classes = useStyles();
    
    let postList = Object.keys(catPosts).map((catPost) => (
      <Grid key={catPosts[catPost].id} item xs={12} sm={2}>
          <Paper className={classes.paper} onClick={()=>navigatePost(catPosts[catPost])}>
            <img height={180} width={200} alt={catPosts[catPost].slug+" cover"}  src={catPosts[catPost].fimg_url?catPosts[catPost].fimg_url:placeholderImg} />
          <Typography variant="h6" dangerouslySetInnerHTML={{__html:catPosts[catPost].title.rendered}}></Typography>
          </Paper>
        </Grid>
    ));
      return (
        <div className={classes.root}>
      <Grid container spacing={3}>
        {postList}
      </Grid>
    </div>
    );

}