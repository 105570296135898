import React from 'react';
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {FormGroup, Grid, Typography, Paper} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Redirect } from "react-router-dom";

export default function Logon({authenticationUpdate, setCookie,getCookie,titleUpdate}) {
    const [loggedIn,setLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [username,setUsername] = React.useState("");
    const [password,setPassword] = React.useState("");
    const [openError,setOpenError] = React.useState(false);
    const [openSuccess,setOpenSuccess] = React.useState(false);
    

    const handleClose = () => {
        setOpen(false);
      };
      function handlefetchErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    
    const handleLogin = () =>{
        setLoading(true);
        fetch("https://admin.utzon.info/wp-json/jwt-auth/v1/token?username="+username+"&password="+password,{
            method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          }).then(handlefetchErrors).then(response => response.json()).then(data=>{
            window.setTimeout(
              () => {
              setLoading(false);
              setOpenSuccess(true);
              handleClose();
              setLoggedIn(true);
              authenticationUpdate(true);
            }, 1000);
            setCookie("jwt",data.token,1);
           
          }).catch( error=>{
            authenticationUpdate(false);
            window.setTimeout(
              () => {
              setLoading(false);
              setOpenError(true);
            }, 1000);
  
          });
      }
      useEffect(()=>{
        open ? setOpen(false):setOpen(false);
        openError ? setOpenError(false):setOpenError(false);
        openSuccess ? setOpenSuccess(false):setOpenSuccess(false);
        titleUpdate("Logon");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
      useEffect(()=>{
        let jwt = getCookie("jwt");
        if(jwt!==""  && jwt !== undefined && loggedIn===false){
          fetch("https://admin.utzon.info/wp-json/jwt-auth/v1/token/validate",{
            method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+jwt
          },
          }).then(handlefetchErrors).then(response=>{setLoggedIn(true);authenticationUpdate(true);}).catch(error=>{console.log("invalid token"); setLoggedIn(false);authenticationUpdate(false);setCookie("jwt","",1)})
          setLoggedIn(false);
        }
      },[getCookie, loggedIn, authenticationUpdate, setCookie]);
      if(loggedIn){
       return <Redirect to={"/home"} />   
      }
      return(
          <div>
              <Grid container spacing={3} alignContent="space-around" alignItems="flex-start" justify="center">
                  <Grid item xs={12}>
                  
                  </Grid>
                  <Grid item xs={8}>
                      <Paper >
                          <Grid container alignContent="space-around" alignItems="center" justify="center">
                              <Grid item xs={10}>
                                    <Typography variant="body1">Velkommen til Utzon Agenternes
                                        Vidensdatabase</Typography>
                                        <Typography variant="body1">
                                        Log på med dit brugernavn og kode</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                <FormGroup>
                                    <TextField
                                        autoFocus
                                        variant="filled"
                                        id="name"
                                        label="Brugernavn"
                                        type="text"
                                        value={username}
                                        onChange={(event)=>{setUsername(event.target.value)}}
                                    />
                                    <TextField
                                        variant="filled"
                                        id="password"
                                        label="Kode"
                                        type="password"
                                        value={password}
                                        onChange={(event)=>{setPassword(event.target.value)}}
                                    />
                                </FormGroup>

                            </Grid>
                            <Grid item xs={9}>
                        {loading? <CircularProgress size={24} />:<Button onClick={handleLogin} color="primary">
                            Log mig ind
                        </Button>}
                    </Grid>
                    </Grid>
                        
                    </Paper>
                </Grid>
                </Grid>
          </div>
      );
    }
