import React from 'react';
import { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import placeholderImg from '../Images/kimuc.png';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  inlineImage:{
    display:"inline-block",
  }
}));

export default function Dashboard({titleUpdate, updateCurrentPost, kategorier}) {
    const history=useHistory();
    const classes = useStyles();
    const [news,setNews] = React.useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    if(error && !isLoaded){
      alert("Der er desværre sket en fejl");
    }
    useEffect(()=>{
      // eslint-disable-next-line react-hooks/exhaustive-deps
      titleUpdate("Utzon Dashboard");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      fetch("https://admin.utzon.info/wp-json/wp/v2/agent_info?per_page=2&order=desc&order_by=modified").then(res=>res.json())
      .then(
          (result) => {
              setIsLoaded(true);
              // eslint-disable-next-line react-hooks/exhaustive-deps
              setNews(result);
              // eslint-disable-next-line react-hooks/exhaustive-deps
             
            },
            (error) => { 
              setIsLoaded(true);
              // eslint-disable-next-line react-hooks/exhaustive-deps
              setError(error);
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }
      )
    },[kategorier]);

    let catList = Object.keys(kategorier).map((kategori) => (
      <Grid key={kategorier[kategori].id} item xs={12} sm={2}>
          <Link to={"/category?name="+kategorier[kategori].name+"&id="+kategorier[kategori].id}><Paper className={classes.paper}>
            <img className={classes.inlineImage} height={180} width={200} alt={kategorier[kategori].slug +" kategori billede"} src={kategorier[kategori].acf.kategori_billede?kategorier[kategori].acf.kategori_billede:placeholderImg} />
          <Typography variant="h6" dangerouslySetInnerHTML={{__html:kategorier[kategori].name}}></Typography>
          <Typography dangerouslySetInnerHTML={{__html:kategorier[kategori].description}}></Typography>
          </Paper></Link>
        </Grid>
    ));
      return (
        <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">NYHEDER</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        {news?<Paper  onClick={()=>{updateCurrentPost(news[0]); history.push("/post")}} className={classes.paper} ><img height={90} width={92} src={news[0].fimg_url?news[0].fimg_url:placeholderImg} /><Typography variant="h5" dangerouslySetInnerHTML={{__html:news?news[0].title.rendered:""}}></Typography></Paper>:""}
        </Grid>
        <Grid item xs={12} sm={6}>
        {news?<Paper  onClick={()=>{updateCurrentPost(news[1]); history.push("/post")}} className={classes.paper} ><img height={90} width={92} src={news[1].fimg_url?news[1].fimg_url:placeholderImg} /><Typography variant="h5" dangerouslySetInnerHTML={{__html:news?news[1].title.rendered:""}}></Typography></Paper>:""}
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h4">Kategorier</Typography>
        </Grid>
        {catList}
      </Grid>
    </div>
    );

}